import { Component, OnInit, Sanitizer } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import {NgWhiteboardService} from 'ng-whiteboard';

@Component({
  selector: 'app-full-screen',
  templateUrl: './full-screen-chat.component.html',
  styleUrls: ['./full-screen-chat.component.scss']
})
export class FullScreenChatComponent {

  name;
    url;
    isShowChat = true;
    enableSign = true;
    color = '#333333';
    backgroundColor = '#f6f6f6';
    size = '5px';

    constructor(
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private whiteboardService: NgWhiteboardService
    ) {
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.name = params.userid;
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl('https://realpulsewebdemo.onrender.com/chat.html?userid=' +
                this.name + '&nickname=' + this.name);
        });
    }

    onClickSign() {
      this.isShowChat = false;
      this.enableSign = false;
    }

    erase() {
        this.whiteboardService.erase();
    }

    cancelSign() {
      this.whiteboardService.erase();
      this.isShowChat = true;
      // this.enableSign = true;
    }

    save() {
        this.whiteboardService.save('my-signature', 'png');
        this.ngOnInit();
        this.isShowChat = true;
        this.enableSign = false;
    }

    addImage(fileInput) {
        const file = fileInput.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            this.whiteboardService.addImage(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    }

}
